// client/src/components/site/RecentStories.jsimport React, { useState, useEffect } from 'react';
import React, { useState, useEffect } from 'react';
import { PageRoute } from 'components';
import './recentStories.css';

// Helper function to safely get the image URL
const getImageUrl = (url) => {
    if (typeof url !== 'string' || url.trim() === '') return '';
    const trimmed = url.trim();
    return trimmed.startsWith('http') ? trimmed : `/images/${trimmed}`;
  };

const RecentStories = ({ currentPageId }) => {
    const [recentStories, setRecentStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRecentStories = async () => {
            try {
                const response = await fetch('/api/pages/recent-stories', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        currentPageId,
                        limit: 5
                    })
                });

                if (!response.ok) throw new Error('Failed to fetch recent stories');
                
                const data = await response.json();
                setRecentStories(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRecentStories();
    }, [currentPageId]);

    if (loading) return <div>Loading recent stories...</div>;
    if (error) return null;
    if (recentStories.length === 0) return null;

    return (
        <div className="recent-stories">
            <h3 className='left'>Recent Posts</h3>
            
            <div className="story-list">
                {recentStories.map(story => (
                    <PageRoute key={story._id} page={story}>
                        <div className="story-row">
                            <div className='grid-item grid-left'> 
                                {story.pageImg && (
                                    <img 
                                    src={ getImageUrl(story.pageImg) }
                                    alt={story.name}
                                    className="thumbnail-img"
                                    onError={(e) => {
                                        e.target.style.display = 'none';
                                    }}
                                />
                                )}
                            </div>
        
                            <div className="grid-item left">
                                <span className="story-title text-one">{story.name}</span><br />
                                <span className="story-date small-text">
                                    {new Date(story.createdAt).toLocaleDateString()}
                                </span>
                            </div>
                        </div>
                    </PageRoute>
                ))}
            </div>
        </div>
    );
};

export default RecentStories;