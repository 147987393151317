// client/src/components/images/ImageSelector.js
import React, { useState } from 'react';
import ImageUploader from './ImageUploader';
import ImageGallery from './ImageGallery';
import './imageSelector.css';

/**
 * Modal component for selecting images from gallery or uploading new ones
 * @param {Object} props
 * @param {Function} props.onSelect - Callback when image is selected
 * @param {Function} props.onClose - Callback when modal is closed
 * @param {boolean} props.isOpen - Whether the modal is open
 */
const ImageSelector = ({ onSelect, onClose, isOpen }) => {
  const [activeTab, setActiveTab] = useState('upload');

  if (!isOpen) {
    return null;
  }

  const handleImageSelect = (imageUrl) => {
    onSelect(imageUrl);
    onClose();
  };

  return (
    <div className="image-selector-modal">
      <div className="image-selector-backdrop" onClick={onClose}></div>
      <div className="image-selector-content">
        <div className="image-selector-header">
          <h2>Select Image</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        
        <div className="image-selector-tabs">
          <button 
            className={`tab-button ${activeTab === 'upload' ? 'active' : ''}`}
            onClick={() => setActiveTab('upload')}
          >
            Upload New
          </button>
          <button 
            className={`tab-button ${activeTab === 'gallery' ? 'active' : ''}`}
            onClick={() => setActiveTab('gallery')}
          >
            Image Gallery
          </button>
        </div>
        
        <div className="image-selector-body">
          {activeTab === 'upload' ? (
            <ImageUploader onUpload={handleImageSelect} />
          ) : (
            <ImageGallery onSelect={handleImageSelect} />
          )}
        </div>
        
        <div className="image-selector-footer">
          <button className="cancel-button" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ImageSelector;