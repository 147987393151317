//client/src/components/user/UserAlias.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth, useApi } from 'context';  // Add useApi import
import './userAlias.css';

const UserAlias = () => {
   const { user } = useAuth();
   const api = useApi();  // Add this hook
   const [isMenuOpen, setIsMenuOpen] = useState(false);
   const [unreadCount, setUnreadCount] = useState(0);

   useEffect(() => {
       if (user) {
           const fetchUnreadCount = async () => {
               try {
                   const response = await api.get('/api/messages/unread-count');
                   setUnreadCount(response.data.count);
               } catch (err) {
                   console.error('Failed to fetch unread messages count:', err);
               }
           };

           // Initial fetch
           fetchUnreadCount();

           // Set up event listener for message updates
           const handleMessagesUpdated = () => fetchUnreadCount();
           window.addEventListener('messagesUpdated', handleMessagesUpdated);

           // Poll for new messages every minute
           const interval = setInterval(fetchUnreadCount, 60000);

           // Cleanup
           return () => {
               window.removeEventListener('messagesUpdated', handleMessagesUpdated);
               clearInterval(interval);
           };
       }
   }, [user, api]);  // Add api to dependencies

   const handleSignOut = () => {
       const logoutUrl = process.env.NODE_ENV === 'development' 
           ? `${process.env.REACT_APP_API_URL}/auth/logout`
           : '/auth/logout';
       window.location.href = logoutUrl;
   };

   return (
       <div className="user-menu">
           <span className="user-alias navbar-link" onClick={() => setIsMenuOpen(!isMenuOpen)}>
               {user.alias}
                         </span>
           {isMenuOpen && (
               <div className="dropdown-menu">

                    <Link to="user/settings" className="dropdown-item" onClick={() => setIsMenuOpen(false)}>
                       Settings
                    </Link>

                    <Link to="#" className="dropdown-item" onClick={handleSignOut}>
                       Sign Out
                    </Link>
               </div>
           )}
       </div>
   );
};

export default UserAlias;