// client/src/services/EmbersImagesService.js
import axios from 'axios';
import config from '../config';

class EmbersImagesService {
  constructor(token = null, apiUrl = process.env.REACT_APP_IMAGES_API_URL) {
    // Try multiple sources for the token
    this.token = token || localStorage.getItem('embersToken');
    
    if (!this.token) {
      console.error('No authentication token found');
      throw new Error('Authentication token not found');
    }
    
    this.apiUrl = apiUrl;
  }

  /**
   * Generate a unique key for the image
   * @param {File} file - The file to generate a key for
   * @returns {string} - Unique key
   */
  generateUniqueKey(file) {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 15);
    return `veylantia-${timestamp}-${randomString}-${file.name}`;
  }

  /**
   * Get a signed URL for S3 upload
   * @param {File} file - The file to upload
   * @returns {Promise<Object>} - Signed URL data
   */
  async getSignedUrl(file) {
    try {
      const key = this.generateUniqueKey(file);
      const response = await axios.post(
        `${this.apiUrl}/s3/signedUrl`,
        {
          key: key,
          type: file.type,
          size: file.size
        },
        {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return {
        ...response.data,
        key: key // Include the key in the returned data
      };
    } catch (error) {
      console.error('Error getting signed URL:', error);
      throw error;
    }
  }

  /**
   * Upload a file to S3 using a signed URL
   * @param {string} signedRequest - The signed URL to upload to
   * @param {File} file - The file to upload
   * @returns {Promise<void>}
   */
  async uploadToS3(signedRequest, file) {
    try {
      await axios.put(signedRequest, file, {
        headers: {
          'Content-Type': file.type
        }
      });
    } catch (error) {
      console.error('S3 Upload Error:', error);
      throw error;
    }
  }

  /**
   * Create image metadata after uploading to S3
   * @param {File} file - The uploaded file
   * @param {Object} s3Data - The S3 upload response data
   * @returns {Promise<Object>} - The created image metadata
   */
  async createImageMetadata(file, s3Data) {
    try {
      const response = await axios.post(
        `${this.apiUrl}/images`,
        {
          url: s3Data.url,
          key: s3Data.key, // Use the same key from getSignedUrl
          type: file.type,
          size: file.size,
          origin: process.env.REACT_APP_SITE_NAME || "", 
          description: file.name
        },
        {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Metadata creation error:', error);
      throw error;
    }
  }

  /**
   * Complete image upload process
   * @param {File} file - The file to upload
   * @returns {Promise<string>} - The URL of the uploaded image
   */
  async uploadImage(file) {
    try {
      // 1. Get signed S3 URL
      const s3Data = await this.getSignedUrl(file);
      
      // 2. Upload to S3
      await this.uploadToS3(s3Data.signedRequest, file);
      
      // 3. Create image metadata to link image to user
      const imageData = await this.createImageMetadata(file, s3Data);
      
      // Return the URL, not the image ID
      return s3Data.url;
    } catch (error) {
      console.error('Image Upload Failed:', error);
      throw error;
    }
  }

  /**
   * Get all images for the current user
   * @returns {Promise<Object>} - Object containing array of images
   */
  async getUserImages() {
    try {  
      // Fetch all images from the external source
      const response = await axios.get(`${this.apiUrl}/images`, {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      });
      // Process the response data
      const images = Array.isArray(response.data)
        ? response.data
        : (response.data.items || []);
  
      return { items: images };
    } catch (error) {
      console.error('Failed to retrieve images:', error.response || error);
      throw error;
    }
  }

  /**
   * Delete an image
   * @param {string} imageId - The ID of the image to delete
   * @returns {Promise<boolean>} - True if deletion was successful
   */
  async deleteImage(imageId) {
    try {
      const response = await axios.delete(
        `${this.apiUrl}/images/${imageId}`,
        {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }
      );
      return response.status === 200;
    } catch (error) {
      console.error('Failed to delete image:', error);
      throw error;
    }
  }
}

export default EmbersImagesService;