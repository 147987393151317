// client/src/components/images/ImageGallery.js
import React, { useState, useEffect } from 'react';
import { useAuth } from 'context';
import { EmbersImagesService } from '../../services';
import './imageGallery.css';

const ImageGallery = ({ onSelect, maxImages = 20 }) => {
  const { user } = useAuth();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      // Don't check for user.token anymore - just check if user is logged in
      if (!user) return;

      setLoading(true);
      setError(null);

      try {
        // Get token from localStorage directly instead of user object
        const token = localStorage.getItem('embersToken');
        
        if (!token) {
          setError('Authentication token not found. Please log in again.');
          setLoading(false);
          return;
        }
        
        const imagesService = new EmbersImagesService(token);
        const response = await imagesService.getUserImages();
        
        // Check if response contains items property
        const imageItems = response.items || response || [];
        
        // Sort by createdAt date (newest first) and limit to maxImages
        const sortedImages = imageItems
          .sort((a, b) => new Date(b.createdAt || 0) - new Date(a.createdAt || 0))
        
        setImages(sortedImages);
      } catch (err) {
        console.error('Failed to fetch images:', err);
        setError('Failed to load images. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [user, maxImages]);

  const handleImageSelect = (imageUrl) => {
    if (onSelect) {
      onSelect(imageUrl);
    }
  };

  const handleImageDelete = async (imageId, e) => {
    e.stopPropagation(); // Prevent triggering select
    
    if (!window.confirm('Are you sure you want to delete this image?')) {
      return;
    }
    
    try {
      const imagesService = new EmbersImagesService(user.token);
      await imagesService.deleteImage(imageId);
      
      // Update images state after deletion
      setImages(images.filter(img => img._id !== imageId));
    } catch (err) {
      console.error('Failed to delete image:', err);
      setError('Failed to delete image. Please try again later.');
    }
  };

  if (loading) {
    return (
      <div className="image-gallery-loading">
        <div className="spinner"></div>
        <p>Loading images...</p>
      </div>
    );
  }

  if (error) {
    return <div className="image-gallery-error">{error}</div>;
  }

  if (images.length === 0) {
    return <div className="image-gallery-empty">No images found</div>;
  }

  return (
    <div className="image-gallery">
      <h3>Your Images</h3>
      <div className="image-grid">
        {images.map(image => (
          <div 
            key={image._id} 
            className="image-item" 
            onClick={() => handleImageSelect(image.url)}
          >
            <img src={image.url} alt={image.description || 'User uploaded image'} />
            <button 
              className="delete-button" 
              onClick={(e) => handleImageDelete(image._id, e)}
              aria-label="Delete image"
            >
              ×
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;