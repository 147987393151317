// client/src/components/images/ImageUploader.js
import React, { useState, useRef } from 'react';
import { useAuth } from 'context';
import { EmbersImagesService } from '../../services';
import './imageUploader.css';

/**
 * Component for uploading images to Embers Images API
 * @param {Object} props
 * @param {Function} props.onUpload - Callback when image is uploaded
 * @param {string} props.initialImage - Initial image URL
 * @param {boolean} props.showLabel - Whether to show the label
 */
const ImageUploader = ({ onUpload, initialImage = '', showLabel = true }) => {
  const { user, token } = useAuth(); // Get token at component level
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [imageUrl, setImageUrl] = useState(initialImage);
  const [previewUrl, setPreviewUrl] = useState(initialImage);
  const fileInputRef = useRef(null);
  const id = useRef(`dropzone-${Math.random().toString(36).substring(2, 15)}`).current;

  const hasImage = !!previewUrl;

  const handleRemove = () => {
    setImageUrl('');
    setPreviewUrl('');
    setErrorMessage(null);
    
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    
    if (onUpload) {
      onUpload(null);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Clear previous data
    setImageUrl('');
    setErrorMessage(null);
    
    try {
      // Get max file size from env vars or use a sensible default
      const MAX_FILE_SIZE = parseInt(process.env.REACT_APP_MAX_IMAGE_SIZE || 0, 10) || 0;
      
      // Get allowed types from env vars or skip validation
      const ALLOWED_TYPES_STR = process.env.REACT_APP_ALLOWED_IMAGE_TYPES || '';
      const ALLOWED_TYPES = ALLOWED_TYPES_STR ? ALLOWED_TYPES_STR.split(',') : [];

      // Only validate file size if MAX_FILE_SIZE is set
      if (MAX_FILE_SIZE > 0 && file.size > MAX_FILE_SIZE) {
        const maxSizeMB = (MAX_FILE_SIZE / (1024 * 1024)).toFixed(1);
        setErrorMessage(`File too large. Please select a file less than ${maxSizeMB}MB.`);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }
      
      // Only validate file type if ALLOWED_TYPES is not empty
      if (ALLOWED_TYPES.length > 0 && !ALLOWED_TYPES.includes(file.type)) {
        setErrorMessage(`Invalid file type. Allowed types: ${ALLOWED_TYPES.join(', ')}`);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }

      // Create preview
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewUrl(e.target.result);
      };
      reader.readAsDataURL(file);
      
      // Set loading state
      setLoading(true);
      
      if (!user) {
        setErrorMessage('You must be logged in to upload images');
        setLoading(false);
        return;
      }

      // Use token from component level or fallback to localStorage
      const authToken = token || localStorage.getItem('embersToken');
      if (!authToken) {
        console.error('Missing authentication token for image upload');
        setErrorMessage('Authentication failed. Please try logging in again.');
        setLoading(false);
        return;
      }
      

      if (!authToken) {
        setErrorMessage('Authentication token not found. Please try logging in again.');
        setLoading(false);
        return;
      }
      
      // Upload image
      const imagesService = new EmbersImagesService(authToken);
      const uploadedUrl = await imagesService.uploadImage(file);
      
      setImageUrl(uploadedUrl);
      
      if (onUpload) {
        onUpload(uploadedUrl);
      }
    } catch (error) {
      console.error('Upload failed:', error);
      setErrorMessage('Upload failed. Please try again.');
      setPreviewUrl('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-group">
      {showLabel && <label className="image-uploader-label">Image</label>}
      <div 
        id={id}
        className={`image-uploader-dropzone ${hasImage ? 'has-image' : ''}`}
      >
        {hasImage && !errorMessage && (
          <button 
            type="button"
            className="remove-button"
            onClick={handleRemove}
            aria-label="Remove image"
          >
            ×
          </button>
        )}
        
        {hasImage && !errorMessage ? (
          <div className="preview">
            <img src={previewUrl} alt="Preview" />
          </div>
        ) : (
          <div className="message">
            <div className="upload-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <circle cx="8.5" cy="8.5" r="1.5"></circle>
                <polyline points="21 15 16 10 5 21"></polyline>
              </svg>
            </div>
            <p>Drop image here or click to upload</p>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
          </div>
        )}

        <input
          ref={fileInputRef}
          id={`file-input-${id}`}
          className="file-input"
          type="file"
          onChange={handleFileChange}
          accept="image/*"
          disabled={loading}
        />
      </div>
      
      {loading && (
        <div className="loading-indicator">
          <div className="spinner"></div>
          <span>Uploading...</span>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;