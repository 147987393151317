// client/src/pages/user/Profile.js
import React from 'react';
import { UserSettings, MessageList, FriendsList, FriendRequests, FriendSearch } from 'components';

const Settings = () => {
   return (
        <div>
            
            <div>
                <h1>User Settings</h1>
                <hr className='hr-small'></hr>
            </div>
            
            <div >
                <div className='left'>
                    <UserSettings />
                </div> 
            </div>

        </div>
   );
};

export default Settings;