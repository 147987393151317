//client/src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Route, Routes as RouterRoutes, useLocation, BrowserRouter } from 'react-router-dom';
import { NavBar, ImageCarousel, Footer } from 'components';
import { AuthProvider, ThemeProvider } from 'context';
import { useTheme } from 'hooks';
import { Home } from 'pages';
import { Routes as AppRoutes } from 'routes';
import './styles';



function ThemedApp() {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [bannerImages, setBannerImages] = useState([]);
  const isHomePage = location.pathname === '/';
  const { theme, toggleTheme } = useTheme();

  useEffect(() => {
    const fetchBannerImages = async () => {
      try {
        const response = await axios.get('/api/pages/banner-images');
        setBannerImages(response.data);
      } catch (err) {
        console.error('Failed to fetch banner images:', err);
        // Fallback to default images if needed
        setBannerImages([
          { 
            src: "/images/site/world/places/gallance/army_marches.jpg", 
            alt: "Gallantian Army on the March" 
          },
          { 
            src: "/images/site/world/places/strykara/veil_sanctuary.jpg", 
            alt: "Veil Sanctuary" 
          }
        ]);
      }
    };

    if (isHomePage) {
      fetchBannerImages();
    }
  }, [isHomePage]);




  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={`App ${theme} ${isHomePage ? 'has-banner' : ''}`}>
      <div className="app-wrapper">
        <NavBar 
          isScrolled={isScrolled} 
          isHomePage={isHomePage} 
          toggleTheme={toggleTheme} 
        />
        
        {isHomePage && (
          <div id="banner">
            <ImageCarousel images={bannerImages} />
          </div>
        )}

        <div id="content" className='page-content'>
          <AppRoutes />
        </div>
        <Footer /> 
      </div>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemedApp />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;