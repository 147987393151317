// client/src/pages/LoginPage.js
import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from 'context';
import { Login } from 'components';

const LoginPage = () => {
  const { isAuthenticated, loading } = useAuth();
  const [errorMessage, setErrorMessage] = useState(null);
  const location = useLocation();
  
  useEffect(() => {
    // Check for error messages in the URL
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    
    if (error) {
      switch (error) {
        case 'no_token':
          setErrorMessage('Authentication failed: No access token received');
          break;
        case 'invalid_token':
          setErrorMessage('Authentication failed: Invalid token');
          break;
        case 'login_failed':
          setErrorMessage('Login failed');
          break;
        case 'callback_error':
          setErrorMessage('An error occurred during authentication');
          break;
        default:
          setErrorMessage('Authentication error');
      }
    }
  }, [location]);
  
  // If user is already authenticated, redirect to home
  if (isAuthenticated && !loading) {
    return <Navigate to="/" replace />;
  }
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  return (
    <div className="login-page">
      <h1>Login to Veylantia</h1>
      
      {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
      )}
      
      <div className="login-options">
        <div className="login-option">
          <h2>Login with Google</h2>
          <Login provider="google" />
        </div>
      </div>
      
    </div>
  );
};

export default LoginPage;
