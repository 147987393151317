// src/components/ImageCarousel/ImageCarousel.js
import React, { useState, useEffect } from 'react';
import './imageCarousel.css'


const getImageUrl = (url) => {
  if (typeof url !== 'string' || url.trim() === '') return '';
  const trimmed = url.trim();
  if (trimmed.startsWith('http')) return trimmed;
  if (trimmed.startsWith('/images/')) return trimmed;
  return `/images/${trimmed.replace(/^\/+/, '')}`;
};

const ImageCarousel = ({ images, delayTime = 10000 }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        (prevIndex + 1) % images.length
      );
    }, delayTime);

    return () => clearInterval(interval);
  }, [images, delayTime]);

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <div className="image-carousel">
      {images.map((image, index) => (
        <img
          key={index}
          src={image.src}
          alt={image.alt}
          className={index === currentImageIndex ? 'active' : ''}
        />
      ))}
      <div className="carousel-dots">
        {images.map((_, index) => (
          <button
            key={index}
            className={`carousel-dot ${index === currentImageIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;