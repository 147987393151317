// client/src/routes/Routes.js
import React from 'react';
import { Routes as RouterRoutes, Route } from 'react-router-dom';
import { Home, PageList, PageView, FictionList, PlaceList, RulesList, FeatView, SpeciesView, EquipmentView, ShortStoryView, Characters, CampaignView, AllPeopleView } from 'pages';
import LoginPage from '../pages/LoginPage'; // Import the new LoginPage
import { AuthCallback } from '../components'; // Add this import
import AdminRoutes from './AdminRoutes';
import RulesRoutes from './RulesRoutes';
import UserRoutes from './UserRoutes';
import GameRoutes from './GameRoutes';

const Routes = () => {
  return (
    <RouterRoutes>
      {/* Home */}
      <Route path="/" element={<Home />} />
     
      {/* Auth Routes */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/sign-in/callback" element={<AuthCallback />} />

     
      {/* Route Groups */}
      <Route path="/user/*" element={<UserRoutes />} />
      <Route path="/admin/*" element={<AdminRoutes />} />
      <Route path="/play" element={<GameRoutes />} />
 
      {/* List Routes with their View Routes */}
      <Route path="/fiction" element={<FictionList />} />
      <Route path="/fiction/:id" element={<PageView />} />
      
      <Route path="/world" element={<PlaceList />} />
      <Route path="/world/:id" element={<PageView />} />
      
      <Route path="/place" element={<PlaceList />} />
      <Route path="/place/:id" element={<PageView />} />
      
      {/* Rules Routes */}
      <Route path="/rules" element={<RulesList />} />
      <Route path="/rules/*" element={<RulesRoutes />} />
      
      {/* Generic Pages */}
      <Route path="/pages" element={<PageList />} />
      <Route path="/pages/:id" element={<PageView />} />

      {/* Specific rule type views */}
      <Route path="/feats/:id" element={<FeatView />} />
      <Route path="/species/:id" element={<SpeciesView />} />
      <Route path="/equipment/:id" element={<EquipmentView />} />
      <Route path="/shortstory/:id" element={<ShortStoryView />} />
      <Route path="/campaigns/:id" element={<CampaignView />} />


      <Route path="/characters" element={<Characters />} />
      <Route path="/whoswho" element={<AllPeopleView />} />

    </RouterRoutes>
  );
};

export default Routes;