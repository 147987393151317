// src/components/NavBar/NavBar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'context';
import { NavLinks, UserAlias } from 'components';
import './navBar.css';

function NavBar({ isScrolled, isHomePage }) {
    const { user, isAuthenticated } = useAuth();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const navbarClass = isHomePage 
        ? `navbar ${isScrolled ? 'scrolled' : ''}`
        : 'navbar scrolled';

    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

    return (
        <nav className={navbarClass}>
            <div className="navbar-content">
                <div className="navbar-left">
                    <Link to="/" className="navbar-brand">Veylantia</Link>
                </div>

                <button className="mobile-menu-button" onClick={toggleMobileMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>

                <div className="navbar-right desktop-menu">
                    <NavLinks />
                    {isAuthenticated ? (
                        <UserAlias />
                    ) : (
                        <Link to="/login" className="navbar-link">Login</Link>
                    )}
                </div>

                {/* Mobile menu overlay */}
                {isMobileMenuOpen && (
                    <div className="mobile-menu-overlay">
                        <button className="close-menu" onClick={toggleMobileMenu}>×</button>
                        <div className="mobile-menu-content">
                            <Link to="/" className="mobile-menu-item" onClick={toggleMobileMenu}>Home</Link>
                            <Link to="/fiction" className="mobile-menu-item" onClick={toggleMobileMenu}>Fiction</Link>
                            <Link to="/world" className="mobile-menu-item" onClick={toggleMobileMenu}>World</Link>
                            <Link to="/rules" className="mobile-menu-item" onClick={toggleMobileMenu}>House Rules</Link>
                            {!isAuthenticated && (
                                <Link to="/login" className="mobile-menu-item" onClick={toggleMobileMenu}>Login</Link>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {isHomePage && !isScrolled && <div className="navbar-line"></div>}
        </nav>
    );
}

export default NavBar;