// src/components/AdminRoute.js
import { Navigate } from 'react-router-dom';
import { useAuth } from 'context';

function ProtectedAdminRoute({ children }) {
  const { user, isAuthenticated } = useAuth();


  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (!user?.isAdmin) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default ProtectedAdminRoute;