// src/config.js

const getServerUrl = () => {
  // If we're on veylantia.com or veylantia.io, use their respective URLs
  if (window.location.hostname === 'veylantia.com') {
    return 'https://veylantia.com';
  }
  if (window.location.hostname === 'veylantia.io') {
    return 'https://veylantia.io';
  }
  // If we're on the Heroku staging domain, use that
  if (window.location.hostname === 'veylantia-site-443282c17454.herokuapp.com') {
    return 'https://veylantia-site-443282c17454.herokuapp.com';
  }
  // Default to localhost for development
  return 'http://localhost:5001';
};

const getClientUrl = () => {
  if (window.location.hostname === 'veylantia.com') {
    return 'https://veylantia.com';
  }
  if (window.location.hostname === 'veylantia.io') {
    return 'https://veylantia.io';
  }
  if (window.location.hostname === 'veylantia-site-443282c17454.herokuapp.com') {
    return 'https://veylantia-site-443282c17454.herokuapp.com';
  }
  return 'http://localhost:3000';
};

// Export as an object for easier imports
export default {
  getServerUrl,
  getClientUrl
};