//client/src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';

// Create a configured axios instance
const api = axios.create({
  baseURL: config.serverUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache'
  }
});

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [lastCheck, setLastCheck] = useState(0);

  // Add error interceptor for 401s
  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.status === 401) {
          setUser(null);
          setIsAuthenticated(false);
        }
        return Promise.reject(error);
      }
    );

    return () => api.interceptors.response.eject(interceptor);
  }, []);

  const checkUser = useCallback(async () => {
    const now = Date.now();
    if (now - lastCheck < 1000) {
      return;
    }
    setLastCheck(now);

    try {
      const res = await api.get('/auth/current_user');
      
      if (res.data) {
        const { _id, embersId, isAdmin, name, picture, email, alias } = res.data;
        setUser({ _id, embersId, isAdmin, name, picture, email, alias });
        setIsAuthenticated(true);
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        setUser(null);
        setIsAuthenticated(false);
        console.error('Auth check failed:', err.response?.data || err.message);
      }
    } finally {
      setLoading(false);
    }
  }, [lastCheck]);

  // Initial auth check
useEffect(() => {
  let mounted = true;
  
 // In the initialCheck function
const initialCheck = async () => {
  // Check for token in URL
  const params = new URLSearchParams(window.location.search);
  const tokenFromUrl = params.get('token');
  
  if (tokenFromUrl) {
    console.log('Token found in URL, storing in localStorage');
    // Store token in localStorage
    localStorage.setItem('embersToken', tokenFromUrl);
    
    // Also store in sessionStorage as backup
    sessionStorage.setItem('embersToken', tokenFromUrl);
    
    // Remove token from URL to prevent it from being shared
    if (window.history && window.history.replaceState) {
      const url = new URL(window.location.href);
      url.searchParams.delete('token');
      window.history.replaceState({}, document.title, url.toString());
    }
  } else {
  }
  
  if (mounted) {
    await checkUser();
  }
};

  initialCheck();
  
  return () => {
    mounted = false;
    setUser(null);
    setIsAuthenticated(false);
  };
}, [checkUser]);

  // Session refresh
  useEffect(() => {
    if (isAuthenticated) {
      const refreshInterval = setInterval(checkUser, 15 * 60 * 1000);
      return () => clearInterval(refreshInterval);
    }
  }, [isAuthenticated, checkUser]);

  const value = {
    user: user ? { ...user } : null,
    loading,
    isAuthenticated,
    token: localStorage.getItem('embersToken'),
    api // Export the configured axios instance
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Export the configured axios instance for use in other components
export const useApi = () => {
  const { api } = useAuth();
  return api;
};