//client/src/pages/pages/PageView.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { AdminEditButton, MarkdownRenderer } from 'components';
import { usePageStyles } from 'hooks';
import { useApi } from 'context';
import config from '../config';
import '../styles/pages/views/pageView.css'

// Helper function to safely get the image URL
const getImageUrl = (url) => {
  if (typeof url !== 'string' || url.trim() === '') return '';
  const trimmed = url.trim();
  return trimmed.startsWith('http') ? trimmed : `/images/${trimmed}`;
};

function PageView() {
    const { id } = useParams();
    const api = useApi();
    const [page, setPage] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [imageError, setImageError] = useState(false);
    const shouldShowImageTitle = page?.pageImg && !imageError;

    const getAuthorFromDetails = (details) => {
        const authorDetail = details?.find(detail => detail.label === 'Author');
        return authorDetail?.value || null;
    };

    const AuthorDisplay = ({ details }) => {
        const author = getAuthorFromDetails(details);
        if (!author) return null;
        
        return (
            <div className="author-display">
                by {author}
            </div>
        );
    };

    const isStylesLoaded = usePageStyles(page?.type, page?.subType);

    useEffect(() => {
        const fetchPage = async () => {
            try {
                const response = await api.get(`/api/pages/${id}`);
                setPage(response.data);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch page');
            } finally {
                setLoading(false);
            }
        };

        fetchPage();
    }, [id, api]);

    if (loading) return <div>Loading page...</div>;
    if (!isStylesLoaded) return <div style={{ visibility: 'hidden' }}>Loading styles...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!page) return <div>No page found</div>;

    return (
        <div className="page-view-container"> {/* begin page container */}

            {/* ---------------------------------------------------------------------- */}
            {/* Enable Admin Functions                                                 */}
            {/* ---------------------------------------------------------------------- */}
            <AdminEditButton id={id} />

            {/* ---------------------------------------------------------------------- */}
            {/* Page Header (controls Title and Banner Image)                          */}
            {/* ---------------------------------------------------------------------- */}
            <div className="page-header">
                {(shouldShowImageTitle && page.tags?.includes('banner')) ? (   
                    <div className="image-container">
                        <h1 className="contained-title">{page.name}</h1>
                        {getImageUrl(page.pageImg) && (
                          <img 
                              src={getImageUrl(page.pageImg)}
                              alt={page.name}
                              className="contained-img"
                          />
                        )}
                    </div>
                ) : (
                    <>
                        <h1>{page.name}</h1>
                        <hr className='hr-small fiction-hr-name' />
                    </>
                )}
            </div>

            {page.trigger && (
                <div className="trigger-warning">
                    <label>Trigger Warning:</label>
                    <MarkdownRenderer content={page.triggerDesc} />
                </div>
            )}
            
            {/* ---------------------------------------------------------------------- */}
            {/* Main Content (description/sidebar/image)                               */}
            {/* ---------------------------------------------------------------------- */}
            <div className='two-column place-two-column-7030 fiction-two-column-6040'> {/* begin first layout div  */}
                <div className='grid-item'> {/* column 1, cell 1  */}
            
                    {/* ------------------------------------------------------------------ */}
                    {/* Main Content: Page Image                                           */}
                    {/* ------------------------------------------------------------------ */}
                    {getImageUrl(page.pageImg) && !imageError && (
                        <div className="page-img-container">
                            <img 
                                src={getImageUrl(page.pageImg)}
                                alt={page.name}
                                className="img-right img-400 img-rounded"
                            />
                        </div>
                    )}
                    {/* ------------------------------------------------------------------ */}
                    {/* Main Content: page.detail.label="Author" display                    */}
                    {/* ------------------------------------------------------------------ */}
                    <div className='author left'>
                        <AuthorDisplay details={page.details} />
                    </div>

                    {/* ------------------------------------------------------------------ */}
                    {/* Main Content: Details Block                                        */}
                    {/* ------------------------------------------------------------------ */}
                    {page.details?.length > 0 && (
                        <div className="page-details left">
                            {page.details.map((detail, index) => (
                                <div key={index} className="detail-item">
                                    <label className='lead-text'>{detail.label}: </label>
                                    <span>{detail.value}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    {/* ------------------------------------------------------------------ */}
                    {/* Main Content: Page Description                                     */}
                    {/* ------------------------------------------------------------------ */}
                    {page.desc && (
                        <div className="page-field desc-div left">
                            <MarkdownRenderer content={page.desc} />
                        </div>
                    )}
                    
                </div> {/* end column 1, cell 1  */}

                <div className='grid-item'> {/* column 1, cell 2  */}
                    <h4>{page.secName}</h4>
                    {page.secDesc && (
                        <div className="page-field desc-div left">
                            <MarkdownRenderer content={page.secDesc} />
                        </div>
                    )}
                </div> {/* end column 1, cell 2  */}
            </div> {/* end first layout div  */}

            {/* ---------------------------------------------------------------------------------------------- */}
            {/*                            Page Sections                                                       */}
            {/* ---------------------------------------------------------------------------------------------- */}
            {page.sections?.length > 0 && (
                <div className="page-sections">
                    <div className=''> {/* begin content div */}
                        {page.sections.map((section, index) => (
                            <React.Fragment key={index}>
                                {/* ----------------------------------------------------------------------------- */}
                                {/* section content                                                               */}
                                {/* ----------------------------------------------------------------------------- */}
                                {/* cell 1 and 2 wrapper */}
                                <div className="two-column-stretch two-column-5050 fill"> {/* Establishes row */}
                                    {/* cell 1  */}
                                    <div className="fill left"> {/* Changed from grid-item */}
                                        <h3>{section.name}</h3>
                                        {section.desc && (
                                            <div className="section-desc">
                                                <MarkdownRenderer content={section.desc} />
                                            </div>
                                        )}
                                    </div>
                                    {/* cell 2  */}
                                    <div className="rightside-container"> 
                                        {section.secDesc ? (
                                            <div className="section-secondary">
                                                {section.secName && <h4>{section.secName}</h4>}
                                                <div className="section-secondary-desc left">
                                                    <MarkdownRenderer content={section.secDesc} />
                                                </div>
                                            </div>
                                        ) : section.secImg && (
                                            <img 
                                                src={getImageUrl(section.secImg)}
                                                alt="Section illustration"
                                                className="rightside-img"
                                            />
                                        )}
                                    </div>
                                </div> {/* End row */}

                                {/* ----------------------------------------------------------------------------- */}
                                {/* section quote                                                                 */}
                                {/* ----------------------------------------------------------------------------- */}
                                {section.quoteDesc && section.quoteDesc !== "" && (
                                    <div className="quote-container">
                                        {section.quoteImg && (
                                            <img 
                                            src={getImageUrl(section.quoteImg)}
                                            alt="Section illustration"
                                            onError={(e) => {
                                              e.target.style.display = 'none';
                                            }}
                                          />
                                        )}
                                        <div className="quote-overlay">
                                            <div className="quote-text">{section.quoteDesc}</div>
                                            <hr className='hr-small' />
                                            {section.quoteAuth && (
                                                <div className="quote-author">- {section.quoteAuth}</div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div> {/* end content div */}
                </div>
            )}
            {/* ---------------------------------------------------------------------------------------------- */}
            {/*                            Page Meta Data                                                      */}
            {/* ---------------------------------------------------------------------------------------------- */}
            <div className='page-metadata'>
                <div className="page-field type-div">
                    <label className='type-label lead-text'>Type: </label>
                    <span>{page.type}</span>
                </div>
                <div className="page-field subtype-div">
                    <label className='subtype-label lead-text'>Sub-Type: </label>
                    <span>{page.subType}</span>
                </div>
                {page.tags?.length > 0 && (
                    <div className="page-field tag-div">
                        <label className='lead-text'>Tags: </label>
                        <span>{page.tags.join(', ')}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PageView;
