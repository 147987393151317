// client/src/pages/admin/pages/EditPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth, useApi } from 'context';
import { 
    PageTab, 
    PageTabContainer, 
    PageFields, 
    PageSections, 
    PageDetails, 
    CancelSaveButtons, 
    DetailEditBlock,
    PageAccessBlock,
    ImageSelector 
} from 'components';
import { EmbersImagesService } from 'services';
import { useFormDirty } from 'hooks';
import '../../../styles/pages/editPage.css';
import './editPageImage.css';

function EditPage() {
    const { id } = useParams();
    const api = useApi();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [page, setPage] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [isImageSelectorOpen, setIsImageSelectorOpen] = useState(false);
    const isDirty = useFormDirty(page);

    useEffect(() => {
        const fetchPage = async () => {
            try {
                const response = await api.get(
                    `/api/pages/${id}?populate=link`,  
                    { withCredentials: true }
                );
                // Initialize contributors array if it doesn't exist
                const pageData = response.data;
                if (!pageData.contributors) pageData.contributors = [];
                setPage(pageData);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch page');
            } finally {
                setLoading(false);
            }
        };

        fetchPage();
    }, [id, api]);

    const handlePageFieldsChange = (updatedFields) => {
        setPage(updatedFields);
    };

    const handleDetailsChange = (updatedDetails) => {
        setPage(prev => ({
            ...prev,
            details: updatedDetails
        }));
    };

    const handleSectionsChange = (updatedSections) => {
        setPage(prev => ({
            ...prev,
            sections: updatedSections
        }));
    };

    const handleAccessChange = (updatedPage) => {
        setPage(updatedPage);
    };

    const handleImageSelect = (imageUrl) => {
        setPage(prev => ({
            ...prev,
            pageImg: imageUrl
        }));
        setIsImageSelectorOpen(false);
    };

    const handleClearImage = () => {
        setPage(prev => ({
            ...prev,
            pageImg: ''
        }));
    };

    const handleSave = async () => {
        setSaving(true);
        setError('');
        
        try {
            const dataToSend = {
                ...page,
                // Ensure these fields are included in the update
                contributors: page.contributors || [],
                visibility: page.visibility || 'public'
            };

            await api.put(
                `/api/pages/${id}`,
                dataToSend,
                { withCredentials: true }
            );
            navigate(-1);
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to save changes');
            setSaving(false);
        }
    };

    if (loading) return <div>Loading page...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!page) return <div>No page found</div>;

    return (
        <div className="edit-page-container">
            <h1>Edit Page</h1>

            {/* Image Management Section */}
            <div className="page-image-manager">
                <h2>Page Image</h2>
                <div className="image-controls">
                    {page.pageImg ? (
                        <div className="image-preview-container">
                            <img 
                                src={page.pageImg} 
                                alt="Page" 
                                className="page-image-preview" 
                            />
                            <div className="image-actions">
                                <button 
                                    type="button" 
                                    className="change-image-button"
                                    onClick={() => setIsImageSelectorOpen(true)}
                                >
                                    Change Image
                                </button>
                                <button 
                                    type="button" 
                                    className="remove-image-button"
                                    onClick={handleClearImage}
                                >
                                    Remove Image
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="no-image-container">
                            <div className="no-image-placeholder">
                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                    <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                    <polyline points="21 15 16 10 5 21"></polyline>
                                </svg>
                                <p>No image selected</p>
                            </div>
                            <button 
                                type="button"
                                className="select-image-button"
                                onClick={() => setIsImageSelectorOpen(true)}
                            >
                                Select Image
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <PageTabContainer>
                <PageTab title="Base Page">
                    <PageFields 
                        page={page} 
                        onChange={handlePageFieldsChange} 
                    />
                </PageTab>

                <PageTab title="Details">
                    <PageDetails 
                        details={page.details || []} 
                        onChange={handleDetailsChange}
                    />
                </PageTab>

                <PageTab title="Sections">
                    <PageSections 
                        sections={page.sections || []} 
                        onChange={handleSectionsChange}
                    />
                </PageTab>

                <PageTab title="Access">
                    <PageAccessBlock 
                        page={page}
                        onChange={handleAccessChange}
                    />
                </PageTab>
            </PageTabContainer>

            <CancelSaveButtons 
                onSave={handleSave}
                saving={saving}
                isDirty={isDirty} 
            />

            {/* Image Selector Modal */}
            <ImageSelector 
                isOpen={isImageSelectorOpen}
                onClose={() => setIsImageSelectorOpen(false)}
                onSelect={handleImageSelect}
            />
        </div>
    );
}

export default EditPage;