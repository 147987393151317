import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AuthCallback = () => {
  const location = useLocation();

  useEffect(() => {
    // Get the token from the URL
    const params = new URLSearchParams(location.search);
    const token = params.get('access_token');

    if (!token) {
      // If no token, navigate to login with error
      window.location.href = `${process.env.REACT_APP_CLIENT_URL || ''}/login?error=no_token`;
      return;
    }

    // Use environment variable for server URL
    const serverUrl = process.env.REACT_APP_API_URL || '';

    // Full-page redirect to the backend endpoint
    window.location.href = `${serverUrl}/auth/sign-in/callback?access_token=${token}`;
  }, [location]);

  return (
    <div className="auth-callback">
      <div className="auth-status">
        <h2>Processing your login...</h2>
      </div>
    </div>
  );
};

export default AuthCallback;
