import React from 'react';
import './login.css';

const Login = ({ provider = 'google' }) => {
  const handleEmbersLogin = () => {
    // Use the SSO URL in both development and production
    const authUrl = `${process.env.REACT_APP_SSO_URL}/v1/sign-in/${process.env.REACT_APP_NEW_SSO_CLIENT_ID}/${provider}`;
    
    window.location.href = authUrl;
  };

  return (
    <button 
      onClick={handleEmbersLogin}
      className="login-button"
    >
      Login with {provider.charAt(0).toUpperCase() + provider.slice(1)}
    </button>
  );
};

export default Login;
